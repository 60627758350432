.root {
    display: flex;
}

.betaLabel {
    font-family: "fresno" !important;
    letter-spacing: 2.2;
    color: #FD4757;
}

.logoImage {
    max-height: 70px;
}

.headerButton, .headerMobileButton {
    height: 40;
    margin-right: 16px !important;
    background-color: #000000 !important;
    border-radius: 6px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 16px !important;
    letter-spacing: none !important;
}

.headerHowButton {
    height: 40;
    margin-right: 16px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 14px !important;
    letter-spacing: none !important;
}


.drawerParent {
    background: #ffffff20 !important;
}

/* main logo dark and lite mode */
.logoImageWhite {
    display: none;
}
.logoImageDark {
    display: block;
}

.drawerParent {
    width: 600px;
}

.mobileRoot {
    padding-top: 16px;
}

.secondaryButtonLine {
    margin: 16px 0px;
}

.headerMobileButton {
    margin-top: 16px;
    padding: 16px;
    width: 100%;
    flex: 1;
}

.headerLogo {
    display: flex;
    align-items: flex-end;
}

@media only screen and (max-width: 693px) {
    .root {
        display: block !important;
    }
}


/* Small */
@media only screen and (max-width: 600px) {
    .root {
        display: block !important;
    }
    .logoImage {
        max-height: 55px;
    }

    .headerMobileButton {
        margin-right: 0px !important;
    }

    .twoHeaderMobileButtonLeft {
        margin-right: 4px !important;
    }

    .twoHeaderMobileButtonRight {
        margin-right: 4px !important;
    }

    .drawerParent {
        width: 100%;
    }

    .secondaryButtonLine {
        flex-direction: column !important;
    }

    .secondaryButtonLine button {
        margin-top: 8px;
    }
}

@media only screen and (max-width: 415px) {

    .headerMobileButton,
    .headerHowButton {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .headerMobileButton {
        width: 100%;
    }

    .twoHeaderMobileButtonLeft {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .twoHeaderMobileButtonRight {
        width: 100% !important;
        margin-right: 0px !important;
    }

}


@media (prefers-color-scheme: dark) {
    /* main logo dark and lite mode */
    .logoImageWhite {
        display: block;
    }
    .logoImageDark {
        display: none;
    }
    .headerButton, .headerMobileButton {
        background-color: #000000 !important;
        border: 1px solid #FFFFFF !important;
        color: #FFFFFF !important;
    }

    .betaLabel {
        color: #65DA78;
    }

    .drawerParent {
        background: #6e6e6e7d !important;
    }

    .headerMobileButton {
        color: #FFFFFF !important;
    }

    .headerHowButton {
        color: #FFFFFF !important;
    }
    
}