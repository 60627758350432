.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: top;
    padding: 32px;
    height: 100%;
    display: table;
    width: 600px;
}

.formParentGrid {
    width: 95% !important;
    padding-left: 5% !important;
}

.formTitle {
    font-weight: 700 !important; 
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    color: #000000;
}

.formDescription {
    color: #000000;
}

.formSubTitle {
    color: #000000 !important;
    font-weight: 700 !important; 
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

.formRecentTitle {
    color: #000000 !important;
    font-weight: 500 !important; 
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

.fullDisplayButtons {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.submitButton {
    padding: 8px 10px !important;
    background-color: #000000 !important;
    border-radius: 6px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 16px !important;
    letter-spacing: none !important;
    margin-bottom: 16px !important;
}

.cancelButton {
    padding: 8px 10px !important;
    border: 1px solid #000000 !important;
    border-radius: 6px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 16px !important;
    letter-spacing: none !important;
    margin-bottom: 16px !important;
}

.formFill {
    background-color: #e7e7e7 !important;
}

.formFill>div {
    background-color: #f0f0f0 !important;
    border-radius: 4px !important;
}

.chipComponent {
    margin-right: 5px;
    margin-top: 5px;
}

.customizationParentTopSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.customizationRevealButton {
    height: 20 !important;
}

.customizationSubTitle {
    color: #000000 !important;
    font-weight: 700 !important; 
    margin: 0 !important;
}

.overrideTooltip {
    font-size: 14px;
    line-height: 18px;
}

.overridePurchaseTitle {
    margin-top: 8px !important;
    margin-right: 16px !important;
    color: #000000;
}

.overridePurchaseButtonActive {
    background: lightgray !important;
}

.overridePurchaseButton {
    background: #ffffff !important;
}

.tooltipBackground {
    background-color:#606060 !important;
}

.tooltipArrow {
    color:#606060 !important;
}

.tooltipIconText {
    font-size: 18px;
    height: 20px;
    color: #000000;
}

.checkboxLabel {
    color: #000000;
}

.checkboxIcon {
    color: #606060 !important;
}

/* Small */
@media only screen and (max-width: 600px) {
    .submitButton {
        height: 35;
    }
    .cancelButton {
        height: 35;
    }

    .root {
        width: 100%;
    }
}

@media (prefers-color-scheme: dark) {
    .root {
        background-color: #000000 !important;
    }

    .formDescription {
        color: #e5e5e5;
    }

    .formTitle {
        color: #e5e5e5;
    }

    .formSubTitle {
        color: #e5e5e5 !important;
    }

    .formRecentTitle {
        color: #e5e5e5 !important;
    }

    .submitButton {
        /* background-color: #484848 !important; */
        color: #FFFFFF !important;
        background-color: #000000 !important;
        border: 2px solid #FFFFFF !important;
    }

    .cancelButton {
        color: #FFFFFF !important;

    }

    .formFill {
        background-color: #FFFFFF !important;
    }

    .alertInfo {
        background-color: rgb(13, 60, 97);
        color: #e5e5e5;
    }

    .errorInfo {
        background-color: rgb(97, 26, 21);
        color: #e5e5e5;
    }

    .customizationSubTitle {
        color: #FFFFFF !important;
    }

    .customizationRevealButton {
        color: #FFFFFF !important;
    }

    .overridePurchaseTitle {
        color: #FFFFFF;
    }

    .checkboxLabel {
        color: #FFFFFF;
    }

    .tooltipBackground {
        background-color: #383838 !important;
    }
    
    .tooltipArrow{
        color: #383838;
    }

    .tooltipIconText {
        color: #FFFFFF;
    }

    .checkboxIcon {
        color: #e5e5e5 !important;
    }

    .overridePurchaseButtonActive {
        background: lightgray !important;
        color: #000000 !important;
    }
    
    .overridePurchaseButton {
        background: #000000 !important;
        border: 1px solid #ffffff !important;
        color: #ffffff !important;
    }
    
}