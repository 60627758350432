.loadingOuterContainer {
    height: 700px;
    background-color: #f7f7f7;
    margin-top: 50px;
}

.loadingOuterContainerLiveLink {
    height: 800px;
    background-color: #fdfdfd;
    margin-top: 50px;
}

.loadingChildContainer {
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.questionLabel {
    font-weight: 700;
}

.logoIcon {
    max-height: 100px;
}

.loadingAnimation {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 10;
    animation-fill-mode: forwards;
    color: #65DA78;
    margin: 60px 0;
}

.createOwn {
    line-height: 50px;
}

.buttonFake {
    padding: 10px;
    border: 1px solid #484848;
    white-space: nowrap;
}

.plusIconInline {
    padding-top: 5px;
}

.mobilePlusIconInline {
    padding-top: 0px;
}

.mobileButtonFake {
    border: none;
    padding: 12px;
}

.brokenError {
    color: #FD4757;
    margin: 60px 0px;
}

.brokenErrorIcon {
    font-size: 60px;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}


/* Small */
@media only screen and (max-width: 600px) {
    .questionLabel {
        font-size: 20px;
    }

    .loadingOuterContainer {
        height: 400px;
        margin-top: 30px;
    }

    .loadingOuterContainerLiveLink {
        height: 500px;
        margin-top: 30px;
    }

    .logoIcon {
        max-height: 50px;
    }

    .loadingAnimation {
        font-size: 18px;
    }

    .createOwn {
        font-size: 18px;
        line-height: 40px;
    }
}


@media (prefers-color-scheme: dark) {
    .loadingOuterContainerLiveLink,
    .loadingOuterContainer {
        background-color: #161616;
    }
}
