.root {
    flex-grow: 1;
}

.gridMainGroup {
    justify-content: center;
}

.paper {
    background-color: #FFFFFF;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.55) 0px 2px 6px 2px;
    border: 1px solid #cccccc;
    height: 240px;
    width: 240px;
    text-align: center;
}


.stockCardHeader {
    font-family: "fresno";
    letter-spacing: "1.5";
    font-weight: 400;
    font-size: 60px;
    margin: 0;
    color: #65DA78;
}
.stockCardBoldHeader {
    font-family: "fresno";
    font-size: 25px;
    padding-right: 10px;
    color: #000000;
}

.stockCardMiddleHeader {
    font-size: 15px;
    color: #000000;
}

.mainValueLabel {
    width: 100%;
    font-family: "fresno";
    padding: 25px 0px 5px 0px;
    letter-spacing: 1.2;
    font-weight: 400;
    font-size: 42px;
    margin: 0;
    text-align: center;
}

.secondaryLabel {
    width: 100%;
    padding: 0px 0px 15px 0px;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    text-align: center;
}

.chipComponent {
    margin-right: 5px;
    margin-top: 5px;
}

.logoImage {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.mainValueLose {
    color: #FD4757;
}

.mainValueGain {
    color: #65DA78;
}

.buttonRecentCard {
    text-decoration: none;
    padding: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.buttonRecentCard>div {
    height: 100%;
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-evenly;
    flex-wrap: nowrap;
}

.stockCardMiddleHeader {
    margin: 0;
}

/* Medium */
@media only screen and (max-width: 1280px) {
    .paper {
        height: 200px;
        width: 200px;
    }
    .stockCardHeader {
        font-size: 50px;
    }
    .stockCardBoldHeader {
        font-size: 20px;
    }
    .stockCardMiddleHeader {
        margin-top: 16px;
        font-size: 12px;
    }
}

/* Small */
@media only screen and (max-width: 600px) {
    .paper {
        height: 100;
        width: 100;
    }
    /* .mainValueLabel {
        font-size: 28px;
    } */
}



@media (prefers-color-scheme: dark) {
    .paper {
        background-color: #000000 !important;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border: 0.5px solid #676767;
    }

    .stockCardBoldHeader {
        color: #e5e5e5;
    }
    .stockCardMiddleHeader {
        color: #adadad;
    }

    .chipComponent {
       color: #e5e5e5 !important;
       border-color:#e5e5e5 !important;
    }
}