.toasterColors {
  background-color: white !important;
}

.mainToolBarParent {
  margin-top: 16px;
}

.paperGroup {
  display: flex;
  border: 1px solid #000000;
  flex-wrap: wrap;
  margin: 8px;
}
.dividerItem {
  margin-left: 2px;
  margin-right: 2px;
}

.buttonActionGroup {
  color: #000000
}

.resetButton {
  font-size: 16px !important;
}

.icon90Degress {
  transform: rotate(90deg);
}

.tooltipBackground {
  background-color: #606060 !important;
}

.tooltipArrow {
  color: #606060 !important;
}

.tooltipIconText {
  font-size: 18px;
  height: 20px;
  color: #000000;
}

.mainHelpSection {
  margin-top: 16px;
}

.helpMainParent {
  justify-content: center;
  max-width: 1000px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.helpMainParent>p {
  text-align: center;
}

.helpColumns {
  display: flex;
}

.helpColumnParent {
  display: flex;
  padding-right: 12px;
  flex: 1;
}

.helpColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap
}

.helpSubTitle {
  font-weight: 700;
  margin: 0 0 12px 0;
  color: #FD4757;
}

.iconGroupItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 12px 0;
}

.iconGroupItem>span {
  font-size: 22px;
  margin-right: 8px;
}

.iconGroupItem p {
  margin: 0
}

.resetIcon {
  font-size: 8px;
  margin-right: 4px !important;
  padding-top: 4px;
}

@media only screen and (max-width: 960px) {
  .helpColumnParent {
    flex: auto;
  }
  .helpColumns {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .buttonCostGroup,
  .buttonGroupItems,
  .buttonOtherGroup,
  .buttonActionGroup {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (prefers-color-scheme: dark) {
  .toasterColors {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border: 1px solid #FFFFFF !important;
  }

  .paperGroup {
    background-color: #000000 !important;
    border: 1px solid #FFFFFF !important;
  }

  .buttonActionGroup>Button,
  .toolbarButton {
    color: #FFFFFF !important;
  }

  .selectedToolbarButton {
    color: #000000 !important;
    background-color: #ffffff !important;
  }

  .dividerItem {
    background-color: #ffffff70 !important;
  }

  .tooltipBackground {
    background-color: #383838 !important;
  }

  .tooltipArrow {
    color: #383838;
  }

  .tooltipIconText {
    color: #FFFFFF;
  }

  .helpSubTitle {
    color: #65DA78;
  }

  .resetButton {
    color: #ffffff !important;
  }
}