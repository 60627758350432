.root {
    flex-grow: 1;
    margin-top: 48px !important;
    padding-bottom: 48px !important;
    background: #000;
    border-top: 1px solid #484848;
}

.gridMainGroup {
    justify-content: center;
    max-width: 1000px;
    padding: 8px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.mainValueLabel {
    width: 100%;
    font-family: "fresno";
    padding: 25px 0px 0px 0px;
    letter-spacing: 1.2;
    font-weight: 400;
    font-size: 42px;
    color: #65DA78;
}

.mainParagraphLabel {
    color: #FFFFFF !important;
    font-size: 18px;
    margin-bottom: 8px;
}

.mainDonateLabel {
    color: #FFFFFF !important;
    font-size: 18px;
    margin-bottom: 8px;
}

.mainCODisclaimer {
    color: #FFFFFF !important;
    font-size: 14px;
    font-weight: 700;
}

.mainDisclaimer {
    color: #adadad !important;
    margin: 0 !important;
}

.logoText {
    font-family: 'fresno';
}

.logoDotText {
    color: #65DA78;
}

.donationButton {
    border: 2px solid white !important;
    color: #FFFFFF !important;
    padding: 15px !important;
}

.donationButton :hover {
    background: #FFFFFF;
}

.stickerParent {
    margin-top: 15%;
    height: 100%;
}

.stickerImage {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.freeStickers {
    font-family: 'fresno';
    color: #e5e5e5 !important;
    text-align: center;
    font-size: 24px;
    margin: 0 !important;
    /* letter-spacing: 1.2px; */
}

.btn {
    border: none;
    background-color: inherit;
    margin: 8px 16px 0 0;
    padding: 8px 8px;
    font-size: 18px;
    cursor: pointer;
    display: inline-block;
    color: #FFFFFF;
    border: 1px solid #ffffff;
    border-radius: 4px;
  }

  .btnChild {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .btnText {
      padding: 0 4px 0 8px;
  }
  
  .success:hover {
    background-color: #FFFFFF;
    color:#000000;
  }

  @media only screen and (max-width: 500px) {
    .btn {
        margin: 16px 0 0 0;
        width: 100%;
    }
  }