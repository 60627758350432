.mainValueLabel {
    font-family: "fresno";
    padding: 10px 0px 10px 0px;
    letter-spacing: 1.2px;
    font-weight: 400;
    font-size: 42px;
    margin: 0;
}

.mainAmountLabel {
    padding: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 24px;
    margin: 0 18px 0;
    align-items: center;
    display: flex;
}

.overrideTooltip {
    font-size: 14px;
    line-height: 18px;
}

.tooltipBackground {
    background-color:#606060 !important;
}

.tooltipArrow {
    color:#606060 !important;
}

.splitIcon {
    color: #000000
}

.mainAmountValueLabel {
    font-family: "fresno";
    padding-right: 10px;
    font-size: 32px;
    letter-spacing: 1.2px;
}

.displayValueLabelLong {
    /* font-family: "Helvetica Neue"; */
    /* padding: 10px; */
    /* letter-spacing: 1.2px; */
    font-weight: 600;
    font-size: 24px;
    margin: 0 12px 0;
}

.displayValueLabelLongCard {
    font-weight: 600;
    font-size: 18px;
    margin: 0 2px 0 0;
}

.mainValueLabelCard {
    font-family: "fresno";
    padding: 0px 0px 2px 0px;
    letter-spacing: 1.2px;
    font-weight: 400;
    font-size: 42px;
    margin: 0;
}

.mainAmountLabelCard {
    padding: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 0 0;
    align-items: center;
    display: flex;
}

@media (prefers-color-scheme: dark) {
    .displayValueLabelLongCard,
    .displayValueLabelLong {
        color: #676767;
    }

    .tooltipBackground {
        background-color: #383838 !important;
    }
    
    .tooltipArrow{
        color: #383838;
    }

    .splitIcon {
        color: #FFFFFF
    }
}