.root {
    flex-grow: 1;
    margin: 48px 0!important;
}

.gridMainGroup {
    justify-content: center;
    max-width: 1000px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.mainValueLabel {
    width: 100%;
    font-family: "fresno";
    padding: 25px 0px 25px 0px;
    letter-spacing: 1.2;
    font-weight: 400;
    font-size: 42px;
    margin: 0;
    text-align: center;
}

.numberCircleParent {
   width: 100%
}

.numberCircle {
    margin: auto;
    width: 40px;
    height: 40px;
    line-height: 35px;
    border-radius: 50%;
    font-size: 20px;
    color: #FD4757;
    text-align: center;
    border: 2px solid #FD4757;
    font-weight: 700;
}

.textSectionParent {
    /* width: 100%; */
    display: flex;
}

.textSectionParent a {
    padding: 10px;
    margin: auto;
}

.textSectionParent span {
    margin: auto;
    font-weight: 500;
}

.text2Form {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.textSection {
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
}

.textSectionSmall {
    margin: auto;
    font-size: 14px;
    color: #484848;
}

.formSectionParent {
    margin: 8px auto;
    width: 90%;
    border: 1px solid #484848;
    border-radius: 25px;
}

.formSectionParent span {
    padding: 8px;
    white-space: nowrap;
    font-size: 18px;
}

.iconInline {
    font-size: 24px;
    padding: 1px 0 3px 0;
}

.plusIconInline {
    font-size: 20px;
    /* padding-top: 2px; */
    margin-right: 8px;
}


.buttonFake {
    padding: 10px;
    background: #000000;
    white-space: nowrap;
    color: #FFFFFF;
    border-radius: 3px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-radius: 6px;
}

.visualizeIcon {
    margin: auto;
    max-height: 60px;
}

/* Small */
@media only screen and (max-width: 960px) {
    .formSectionParent {
        width: 50% !important;
    }
}

@media only screen and (max-width: 500px) {
    .formSectionParent {
        width: 100% !important;
    }
}


@media (prefers-color-scheme: dark) {
    .numberCircle {
        color: #65DA78;
        border: 2px solid #65DA78;
    }

    .textSectionSmall {
        color: #707070;
    }

    .buttonFake {
        border: 1px solid #FFFFFF;
    }
}