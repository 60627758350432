.graphSectionContainer {
    border-left: 2px solid #DEDEDE;
    border-right: 2px solid #DEDEDE;
}

.customSectionBoxWidth {
    max-width: 1000px;
    margin: auto;
}

.customSectionCardWidth {
    max-width: 675px;
    padding: 20px;
    /* background-color: #00000008; */
    margin: auto;
    border-radius: 20px;
    /* border: 2px solid #000000; */
    background-color: #FFFFFF;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.55) 0px 2px 6px 2px;
    border: 1px solid #cccccc;
}

@media (prefers-color-scheme: dark) {
    .graphSectionContainer {
        border-left: 2px solid #323232;
        border-right: 2px solid #323232;
    }

    .customSectionCardWidth {
        background-color: #000000 !important;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border: 0.5px solid #676767;
    }
}