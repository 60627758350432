.parentCardTooltip {
    font-family: "fresno";
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px;
}

.parentCardTooltipLeft {
    border-top: 0.5px solid #DEDEDE;
    border-right: 0.5px solid #DEDEDE;
    border-bottom: 0.5px solid #DEDEDE;
    text-align: left;
}

.parentCardTooltipRight {
    border-top: 0.5px solid #DEDEDE;
    border-left: 0.5px solid #DEDEDE;
    border-bottom: 0.5px solid #DEDEDE;
    text-align: right;
}

.parentCardTooltipRightCard {
    border-top: 0.5px solid #DEDEDE;
    border-left: 0.5px solid #DEDEDE;
    border-bottom: 0.5px solid #DEDEDE;
    border-right: 0.5px solid #DEDEDE;
    text-align: right;
}

.mainValueCardTooltip {
    letter-spacing: 1.2px;
    font-weight: 400;
    font-size: 42px;
    margin: 0;
}

.mainValueCardTooltipCard {
    letter-spacing: 1.2px;
    font-weight: 400;
    font-size: 32px;
    margin: 0;
}

.dateValueCardTooltip {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
}

.dateValueCardTooltipCard {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.topLabelCardTooltip {
    /* font-family: "Helvetica Neue"; */
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    color: #a0a0a0;
}

.topLabelCardTooltipCard {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    color: #a0a0a0;
}

.mainValueInitial {
    color: #676767;
}

.mainValueLose {
    color: #FD4757;
}

.mainValueGain {
    color: #65DA78;
}
.parentCardTooltipRightCard .dateCardLine,
.parentCardTooltipRight .dateCardLine {
    justify-content: flex-end;
}

.dateCardLine {
    display: flex;
    align-items: center;
}

.noLongerTradingWarningIcon {
    color: #EDc242
}

.noLongerTradingTooltipP {
    font-size: 14px;
    line-height: 18px;
}

.tooltipBackground {
    background-color:#606060 !important;
}

.tooltipArrow {
    color:#606060 !important;
}


@media (prefers-color-scheme: dark) {
    .mainValueInitial {
        color: #adadad;
    }

    .parentCardTooltip {
        box-shadow: rgba(167, 177, 185, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    
    .parentCardTooltipLeft {
        border-top: 0.5px solid #676767;
        border-right: 0.5px solid #676767;
        border-bottom: 0.5px solid #676767;
        text-align: left;
    }
    
    .parentCardTooltipRight {
        border-top: 0.5px solid #676767;
        border-left: 0.5px solid #676767;
        border-bottom: 0.5px solid #676767;
        text-align: right;
    }

    .parentCardTooltipRightCard {
        border-top: 0.5px solid #676767;
        border-left: 0.5px solid #676767;
        border-bottom: 0.5px solid #676767;
        border-right: 0.5px solid #676767;
        text-align: right;
    }

    .tooltipBackground {
        background-color: #383838 !important;
    }
    
    .tooltipArrow{
        color: #383838;
    }
}